.footerContainer,
.dashboardFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
}

.footerContainer {
  z-index: 1;
}

.copyrightAndSocials {
  display: flex;
  flex-direction: row;
}

.copyrightAndTitle {
  display: flex;
  flex-direction: row;
}

.developerInfo {
  display: flex;
  flex-direction: row;
}

.copyrightTextt {
  margin-right: 1.5rem;
}

@media only screen and (max-width: 1120px) {
  .footerContainer,
  .dashboardFooter {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}

@media only screen and (max-width: 725px) {
  .copyrightAndSocials,
  .copyrightAndTitle {
    display: flex;
    flex-direction: column;
    margin-right: none;
  }

  .copyrightTextt {
    margin-right: 0 !important;
  }

  .copyrightAndTitle {
    gap: 1.5rem;
  }

  .footerLoginContainer {
    padding: 1.5rem !important;
  }
}
