.Navbar {
  width: 248px;
  position: fixed;
  height: 100%;
  padding-left: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  background-color: #ffffff !important;
}

.Navbar .NavbarHamburger {
  display: none;
}

.BelaLogo {
  width: 6rem;
  margin-top: 25px;
  margin-bottom: 3rem;
}

.nav-menu-items {
  width: 85%;
}

.nav-menu-items span {
  margin-left: 14px;
}

.active {
  text-decoration: none;
  background-color: #edf3f5;
  border-radius: 20rem;
  width: 95%;
  border: 1px solid #e1e7e9;
}

#user-navLink:focus,
#user-navLink:hover {
  text-decoration: none;
  background-color: #edf3f5;
  border-radius: 20rem;
  width: 95%;
  color: #000000;
}

#user-navLink:focus {
  background-color: #edf3f5;
}

#user-navLink:hover {
  background-color: #edf3f56e;
}

.navbar-disabled-link {
  pointer-events: none;
  color: #6c6c6c;
}

.navbar-active-link {
  color: #000000;
}

.navLink-wrapper {
  display: flex;
  flex-direction: column;
}

.cardLogoutContainer {
  position: absolute;
  bottom: 0;
  margin-bottom: 2rem;
}

.belaCard {
  width: 250px;
  border-radius: 20px;
  height: 17.5rem;
  background: url('../../assets/offersBannerBackground.png');
  background-size: cover;
  background-position: center;
}

.belaCardText {
  margin-top: 3rem !important;
  font-size: 16px !important;
  font-family: 'Nunito', sans-serif !important;
  text-align: center;
}

.browseServicesBtn {
  font-family: 'Nunito', sans-serif !important;
  text-align: center;
  text-decoration: none !important;
  text-decoration-color: none !important;
  font-size: 16px;
}

.browseServicesBtn:hover {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  color: #000000;
}

.divLogOut {
  display: flex;
  flex-direction: row;
  color: black;
  margin: 25px 0px 0px 18px;
  font-weight: bold;
  color: #000;
  font-size: 16px;
  gap: 9px;
}

@media screen and (max-height: 655px) {
  .BelaLogo {
    margin-bottom: 30px;
  }

  .belaCard {
    height: 195px !important;
  }

  .belaCard .MuiCardContent-root {
    padding-bottom: 0.3rem;
  }

  .belaCardText {
    margin-top: 1rem !important;
  }

  .browseServicesBtn {
    margin-top: 0rem !important;
  }

  .cardLogoutContainer {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 900px) {
  .NavbarHamburger {
    display: block;
  }

  .Navbar {
    display: none;
  }
  .NavbarHamburger .MuiDrawer-paperAnchorLeft {
    width: 248px;
    padding-left: 15px;
  }

  .hamburger-menu-items span {
    padding-left: 14px;
  }
}
