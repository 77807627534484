.cardContainerStartAssessment {
  text-align: left;
  padding: 2rem;
  color: white;
  font-family: 'Nunito';
  border-radius: 15px;
  height: 15rem;
}

.cardContainerStartAssessment > h1 {
  margin-top: 0;
  font-weight: 900;
}

.cardContainerStartAssessment > p {
  width: 70%;
}

.cardTitle {
  margin-top: 0;
}

.cardContainerStartAssessment > * {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media screen and (max-width: 1272px) {
  .cardContainerStartAssessment {
    height: 14rem;
    padding: 1.5rem;
  }
}
