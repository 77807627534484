.fiveLatestGridHeader {
  display: grid !important;
  grid-template-columns: 1fr 18%;
  grid-template-areas: 'title filter';
}

.fiveLatestGridHeader h3 {
  grid-area: title;
}

.fiveLatestGridHeader button {
  grid-area: filter;
}

.MuiDataGrid-main.css-204u17-MuiDataGrid-main {
  background: #fff;
  border-radius: 15px;
}

.MuiDataGrid-columnHeaderTitleContainer {
  display: flex;
  justify-content: center;
}
.MuiDataGrid-cell--textMiddle {
  display: flex;
  justify-content: center;
}
.MuiDataGrid-panel {
  margin-left: 700px !important;

  border-radius: 5px;
}
.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}

.MuiDataGrid-footerContainer.css-17jjc08-MuiDataGrid-footerContainer {
  display: none;
}

@media screen and (max-width: 1272px) {
  .fiveLatestGridHeader {
    grid-template-columns: 1fr 25%;
  }
}
