.yourResultsPage {
  height: 100vh;
  padding-bottom: 3rem;
  margin-top: 4rem;
  margin-left: 80px;
  margin-right: 80px;
  display: grid;
  grid-template-columns: 1fr 30%;
  grid-template-rows: 40% 1fr;
  column-gap: 25px;
  grid-template-areas:
    'leftComponents rightSideBar'
    'leftComponents rightSideBar';
}

.leftComponents {
  grid-area: leftComponents;
}

.yourResultsTitle {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 20px;
}

.yourResultsTitle h1 {
  margin: 0;
  padding: 0;
}

.yourResultsRightColumn {
  grid-area: rightSideBar;
  display: flex;
  justify-content: flex-start;
}

.yourResultsleftColumnBottomHalf {
  display: flex;
}

@media only screen and (max-width: 900px) {
  .yourResultsPage {
    margin-left: 40px;
    margin-right: 40px;
    grid-template-columns: 1fr;
    grid-template-rows: 40% 1fr;
    column-gap: 25px;
    grid-template-areas: 'leftComponents' 'rightSideBar';
  }
  .yourResultsleftColumnBottomHalf {
    display: none;
  }
}

@media only screen and (min-width: 1500px) {
  .yourResultsPage {
    width: 80%;
  }
}

@media screen and (max-height: 655px) {
  .yourResultsPage {
    margin-top: 2rem;
  }
}
