.offersContainer {
  margin-top: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.offersRow {
  display: flex;
  justify-content: flex-start;
}
.offersContent {
  background-color: white;
  border-radius: 15px;
  height: 10.5rem;
}
.offersDescriptionContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.offersDescription {
  padding: 15px;
  width: 80%;
}
.offersButton {
  background-color: transparent;
  border: solid 1px;
  border-color: grey;
  width: 150px;
  border-radius: 15px;
  padding: 5px;
}
.offersTitle {
  padding-bottom: 20px;
}
