.OrganizationHome {
  display: grid;
  grid-template-columns: 248px 1fr;
  grid-template-rows: 40px 1fr 40px;
  grid-template-areas:
    'Navbar header'
    'Navbar orgComponentsContainer'
    'Navbar footer';
  background-color: rgb(247, 248, 252);
  /* height: 100vh; */
  width: 100vw;
  padding: 0;
  height: 100vh;
}

.Navbar {
  grid-area: sidenav;
  text-align: left;
}

.orgComponentsContainer {
  grid-area: orgComponentsContainer;
  margin-right: 15px;
  text-align: left;
  height: fit-content;
  padding: 1rem 25px 6rem 25px;
  margin: 0 auto;
  overflow: scroll;
}

.dashboardFooter {
  grid-area: footer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
}

.orgInfo {
  display: flex;
  flex-direction: row;
  row-gap: 25px;
  height: 100%;
}

@media screen and (max-width: 900px) {
  .OrganizationHome {
    grid-template-columns: 1fr;
  }

  .OverviewUsers {
    margin-top: 80px;
  }

  .orgComponentsContainer {
    padding: 1rem 35px 6rem 0;
  }

  .orgInfo {
    row-gap: 0;
  }
}

@media screen and (max-width: 900px) {
  .orgInfo {
    flex-direction: column;
  }
}

@media only screen and (min-width: 1500px) {
  .orgComponentsContainer {
    width: 80%;
  }
}
