.myCertTextTest {
  margin-left: 0;
  font-size: x-large;
  margin-bottom: 20px;
}

.rightSideBarTestContainer {
  margin-top: 3.6rem;
}

.rightSideBarTest {
  background-color: #fff;
  display: flex;
  background-color: white;
  border-radius: 15px;
  flex-grow: 1;
  font-family: 'Nunito', sans-serif;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  margin-right: none;
}

.cardBarTest {
  width: 100%;
  flex-direction: column;
}

.certCardTest {
  margin: 0;
  border-radius: 20px;
  width: 100%;
  height: 20rem;
  background: url('../../assets/certificateBanner.png');
  background-position: center;
  background-size: cover;
  border-radius: 10;
  background-repeat: no-repeat;
}

.cardContentTest {
  margin-top: 12rem;
  text-align: center;
}
.textMid {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
}
.textEndTest {
  margin-top: 2rem;
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
  text-align: left;
  color: #000000;
}

.btnGetTest {
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  font-weight: 700;
  background-color: #fdb12a;
  color: #000;
  padding: 15px 50px;
  text-align: center;
  text-decoration: none;
  border-radius: 26px;
  border: #fdb12a;
  width: 100%;
  cursor: pointer;
}

.btnGet:hover {
  transform: scale(1.04, 1);
  background-color: #fdb12a;
  text-decoration: none;
}

@media screen and (max-width: 900px) {
  .rightSideBarTest .cardBarTest {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .sideBarTestContents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .certCardTest {
    height: 40vh;
  }

  .cardContentTest {
    margin-top: 12.5rem;
  }
}

@media screen and (max-width: 1272px) {
  .rightSideBarTest {
    padding: 1.2rem;
  }

  .myCertTextTest {
    margin-bottom: 29px;
  }
  .btnGetTest {
    padding: 15px;
  }
}

@media screen and (max-width: 1300px) {
  .cardContentTest {
    margin-top: 9rem !important;
  }
}

@media screen and (max-height: 655px) {
  .certCardTest {
    height: 18rem;
  }
}
