.emailVerifiedBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  align-self: center;
  margin: 0 auto;
  width: 513px;
  height: 471px;
  background-color: #ffffff;
  border-radius: 30px;
  padding: 1.5rem;
  box-shadow: rgba(149, 157, 165, 0.15) 0px 8px 24px;
}

.text {
  width: 100%;
  margin-bottom: 2rem;
}
