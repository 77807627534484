.GetCertificate {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.GetCertificateCard {
  height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 29px;
  width: 100%;
  padding: 0 2rem 0 2rem;
}

.GetCertificateContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.GetCertificateButton {
  font-family: 'Nunito', sans-serif;
  width: 100%;
  height: 52px;
  border-radius: 26px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  background-color: #fdb12a;
  border: none;
}

.GetCertificateButton:hover:enabled {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.GetCertificateButton:active:enabled {
  background-color: #f29b00;
  box-shadow: none;
}
.GetCertificateButton:disabled {
  background-color: #d8d8d8;
  color: #828282;
}

@media screen and (max-width: 1272px) {
  .GetCertificateCard {
    height: 14rem;
    margin-top: 29px;
  }
}

@media screen and (max-width: 1190px) {
  .GetCertificate h2 {
    margin-top: 60px !important;
  }
}
