.mediumButton {
  width: 278px;
  height: 52px;
  border-radius: 26px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  background-color: #fdb12a;
  border: none;
}
.largeButton:hover:enabled {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.mediumButton:hover:enabled {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.mediumButton:active:enabled {
  background-color: #f29b00;
  box-shadow: none;
}
.mediumButton:disabled {
  background-color: #d8d8d8;
  color: #828282;
}
