.PaymentSuccessful {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.paymentSuccessfulItems {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.checkIconPayment {
  transform: scale(4);
  margin-bottom: 3rem;
}
