.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #000000;
}

.mainContent {
  background-color: rgb(247, 253, 252);
  flex-grow: 2;
  background-color: #f7f8fc;
}

.App-header {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
