.MuiDataGrid-columnHeaderTitleContainer {
  display: flex;
  justify-content: center;
}
.MuiDataGrid-cell--textMiddle {
  display: flex;
  justify-content: center;
}
.MuiDataGrid-toolbarContainer {
  margin-top: -3.5rem !important;
  border-radius: 5px;
}
.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}

.MuiDataGrid-footerContainer.css-17jjc08-MuiDataGrid-footerContainer {
  display: none;
}
.MuiDataGrid-columnSeparator {
  visibility: hidden;
}
