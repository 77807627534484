.CompanyCode {
  margin-right: 20px;
  width: 95%;
  height: 100%;
}

.CompanyCode h2 {
  font-size: 24px;
  font-weight: 800;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 20px;
}

.codeContainer {
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  padding: 1.5rem;
  height: 100%;
}

.codeContainer p {
  width: 60%;
  margin-bottom: 0;
}

.codeShareButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: transparent;
  border: 1px solid #608898;
  border-radius: 10px;
  font-size: 24px;
  width: 40%;
  min-height: 35%;
}

.codeShareButton:hover:enabled {
  background-color: #177091;
  box-shadow: none;
}

.copiedSuccess {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  justify-content: center;
  align-items: center;
}

.codeExplanation {
  padding-left: 1.5rem;
  align-self: center;
}

@media screen and (max-width: 900px) {
  .CompanyCode {
    width: 100%;
    margin-right: 0;
  }

  .codeContainer {
    flex-direction: row;
  }
}

@media screen and (min-width: 901px) and (max-width: 1429px) {
  .codeContainer {
    flex-direction: column;
  }
  .codeExplanation {
    margin-left: 0;
    padding-left: 0rem;
    padding-top: 1rem;
    width: 100% !important;
  }
  .codeShareButton {
    width: 80%;
  }
}
