.belaSignInHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  border: none;
}
.noHeaderDisplay {
  display: none;
}
.belaLogo {
  margin: 1rem 2rem;
  width: 8%;
}
.signInLink {
  margin: 0rem 2rem;
}
.signInLinkButton {
  border: none;
  background-color: transparent;
  text-decoration: underline;
  font-weight: bold;
}

.signInLinkButton a {
  color: #000000;
  font-size: 18px;
}

.signInLinkButton a:hover {
  color: #000000;
}
