.progressBarContainer {
  width: 80vw;
  margin: 0 auto;
  margin-top: 1rem;
  background-color: white;
  padding: 1rem;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #000000;
}

.progress {
  width: 60%;
  border-radius: 50px;
}

.questionType {
  font-weight: 700;
  width: 28%;
}

.timer {
  font-weight: 700;
  width: 12%;
}

@media only screen and (max-width: 900px) {
  .progressBarContainer {
    margin-top: 0rem;
    width: 95vw;
    padding: 0.5rem;
  }
}

@media screen and (max-height: 655px) {
  .progressBarContainer {
    margin-top: 0rem;
    padding: 0.5rem;
  }
}
