@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;800&display=swap');
html,
* {
  font-family: 'Nunito', sans-serif;
}
body {
  margin: 0;
  background-color: #f7f8fc;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
