.levelComponent {
  display: flex;
  border-radius: 10px;
  width: 100%;
  margin-top: 10px;
  height: 15rem;
  padding: 1.5rem;
  gap: 1.5rem;
}

.column1TopTitleLevelComponent {
  font-size: 0.9rem;
  color: #000000;
}
.column1BottomTitle {
  background-color: #c1e7ff;
  border-radius: 25px;
  padding: 1px 10px 1px 10px;
  margin-top: 8px;
  font-size: 10px;
}
.column1LevelComponent {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-right: solid rgba(128, 128, 128, 0.185);
  margin: 15px 0px 15px 0px;
  justify-content: center;
  width: 30%;
  gap: 1rem;
}
.column1PercentageLevelComponent {
  font-size: 40px;
  color: #000000;
}
.column2LevelComponent {
  width: 70%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.sectionsRowLevelComponent {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
  height: 70%;
}
.sectionColumnLevelComponent {
  padding: 10px;
  border-radius: 10px;
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: center;
}
.column2TopTitleLevelComponent {
  width: 100%;
  display: flex;
  align-items: flex-start;
  font-weight: 800;
  margin-bottom: 1rem;
  color: #000000;
}
.levelCompIcon {
  width: 46px;
  height: 48px;
  padding: 5px 0px 5px 0px;
}
.centerComp {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1272px) {
  .levelComponent {
    height: 14rem;
  }
}
