.boxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 0 auto;
  width: 513px;
  height: 471px;
  background-color: #ffffff;
  border-radius: 30px;
  padding: 1.5rem;
  box-shadow: rgba(149, 157, 165, 0.15) 0px 8px 24px;
}

.passwordResetContainer {
  flex-direction: column !important;
  flex-grow: 2;
  height: 100vh;
}

.boxContainer > h1 {
  font-weight: 800;
}

.successMessageContainer {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  background-color: #eeffed;
  border-radius: 15px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.successMessageContainer p {
  margin: 0;
  font-weight: 600;
}

.resendPasswordReset {
  margin-top: 4rem;
  font-size: 18px;
}

.resendButton {
  align-self: flex-end;
  background-color: white;
  border: none;
  font-weight: bold;
  font-size: 18px;
  text-decoration: underline;
}
