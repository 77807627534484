body.active-modal {
  overflow-y: hidden;
}

.UploadPreviewContainer {
  display: block;
  width: 100%;
}

.certificateSample {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}

.dottedBorder {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='17' ry='17' stroke='black' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 16px;
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.uploadedFileContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: 1rem;
}

.dropBoxText {
  margin-left: 0;
  margin-right: 0;
  text-align: center;
}
.fileImage {
  font-size: xx-large;
  color: #b1b1b1;
}
.browseFile {
  display: inline-block;
  cursor: pointer;
  color: #000;
  margin-left: 2px;
  font-weight: bold;
  padding: 10px 16px;
  text-decoration: underline;
  padding: 0;
  border: none;
  background: none;
  font-size: 16px;
}
.btnExitMedia {
  margin-top: 3rem !important;
  gap: 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.btnExitMedia button {
  font-family: 'Nunito';
}

.btnCancelMedia {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  margin-right: 2rem;
  background-color: transparent;
  border-style: none;
  text-decoration: underline;
  cursor: pointer;
}

.imgPrev {
  width: 100%;
}

.modal-media-uploader,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}

.upload-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  align-self: center;
  line-height: 1.4;
  background: #fff;
  padding: 14px 28px;
  border-radius: 30px;
  width: 50rem;
}

.upload-modal-content p {
  text-align: left;
}

.admin-close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
  font-weight: bolder;
  font-size: 50px;
  cursor: pointer;
}
.mediaPreview img {
  width: 200px;
  margin-top: 2rem;
}

.generateLink {
  width: 278px;
  height: 52px;
  border-radius: 26px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  background-color: #fdb12a;
  border: none;
  cursor: pointer;
}

.generatedLinkContainer {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: flex-start;
  width: 100%;
}

.generatedLinkContainer span {
  width: 20%;
  text-align: left;
}

.generatedLinkContainer p {
  text-align: left;
  width: 80%;
  color: #0000ee;
  text-decoration: underline;
}

.proceedCheckoutButton:hover:enabled {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.proceedCheckoutButton:active:enabled {
  background-color: #f29b00;
  box-shadow: none;
}

@media only screen and (max-width: 1135px) {
  .modal-content {
    min-width: 90%;
    min-height: 90%;
  }
}

@media only screen and (max-width: 850px) {
  .btnExitMedia {
    max-width: 100%;
    font-size: 5px;
  }

  .modal,
  .overlay {
    width: auto;
    height: auto;
  }
}

@media only screen and (max-width: 900px) {
  .modal,
  .overlay,
  .upload-modal-content {
    overflow: scroll;
  }
  .upload-modal {
    width: 50vh;
    height: 50vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
  }

  .dottedBorder {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='17' ry='17' stroke='black' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");
  }
  .overlay {
    background: rgba(49, 49, 49, 0.8);
  }

  .upload-modal-content {
    width: 90%;
    background-color: #fff;
  }

  .dottedBorder {
    height: 15rem;
  }

  .upload-modal-content h2 {
    font-size: large;
  }

  .btnExitMedia {
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 1280px) and (max-height: 600px) {
  .dottedBorder {
    height: 10rem;
  }
}
