.levelComponentTest {
  display: flex;
  border-radius: 10px;
  height: 15rem;
  width: 100%;
  padding: 1.5rem;
  gap: 1.5rem;
}
.column1Title {
  font-size: 0.9rem;
}
.column1BottomTitle {
  background-color: #c1e7ff;
  border-radius: 25px;
  padding-top: 1px 10px 1px 10px;
  margin-top: 8px;
  font-size: 10px;
}
.column1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-right: solid rgba(128, 128, 128, 0.185);
  margin: 15px 0px 15px 0px;
  justify-content: center;
  width: 30%;
  gap: 1rem;
}
.column1Percentage {
  font-size: 40px;
}
.column2 {
  width: 70%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.sectionsRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
  height: 70%;
}
.sectionColumn {
  padding: 10px;
  border-radius: 10px;
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: center;
}
.column2TopTitle {
  width: 100%;
  display: flex;
  align-items: flex-start;
  font-weight: 800;
  margin-bottom: 1rem;
}

@media screen and (max-width: 1272px) {
  .levelComponentTest {
    height: 14rem;
  }
}
