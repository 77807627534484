.adminSideBar {
  width: 248px;
  position: fixed;
  height: 100%;
  padding-left: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  background-color: #ffffff !important;
  text-align: left !important;
}

.adminBelaLogo {
  width: 5rem;
  margin-top: 25px;
  margin-bottom: 3rem;
}

.admin-nav-menu-items {
  width: 85%;
}

.admin-nav-menu-items span {
  margin-left: 14px;
}

.disabled-link {
  pointer-events: none;
  color: #6c6c6c;
}

.active-link {
  color: #000000;
}

#admin-navLink:focus,
#admin-navLink:hover {
  text-decoration: none;
  border-radius: 20rem;
  width: 95%;
}

#admin-navLink:focus {
  background-color: #edf3f5;
}

#admin-navLink:hover {
  background-color: #edf3f56e;
  color: #000000;
}

.active {
  text-decoration: none;
  background-color: #edf3f5;
  border-radius: 20rem;
  width: 95%;
  border: 1px solid #e1e7e9;
}

.adminSideBar-wrapper {
  display: flex;
  flex-direction: column;
}

.adminCardLogoutContainer {
  position: absolute;
  bottom: 25px;
  margin-top: 2rem;
}

.adminCardLogoutContainer .divLogOut {
  display: flex;
  flex-direction: row;
  color: black;
  margin: 25px 0px 0px 18px;
  font-weight: bold;
  color: #000;
  font-size: 16px;
  gap: 9px;
}

@media screen and (max-width: 900px) {
  .AdminNavbarHamburger {
    display: block;
  }

  .adminSideBar {
    display: none;
  }
  .AdminNavbarHamburger .MuiDrawer-paperAnchorLeft {
    width: 248px;
    padding-left: 15px;
  }

  .admin-hamburger-menu-items span {
    padding-left: 14px;
  }
}
