.CEFRcontentContainer {
  width: 100%;
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  column-gap: 3rem;
}

.CEFRcontentContainer {
  width: 100%;
}

.CEFRimageContainer {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CEFRimage {
  width: 100%;
}

.CEFRlearnMore {
  width: 30%;
  background-color: #f3f8fa;
  border-radius: 15px;
  padding: 1.5rem;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.learnMoreBtn {
  font-family: 'Nunito', sans-serif !important;
  text-align: center;
  text-decoration: none !important;
  text-decoration-color: none !important;
  font-size: 16px;
  width: 90%;
}

.learnMoreBtn:hover {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  color: #000000;
}

@media screen and (max-width: 1190px) {
  .CEFRcontentContainer {
    flex-direction: column;
    gap: 2rem;
  }

  .CEFRlearnMore {
    width: 50%;
  }

  .CEFRimageContainer {
    width: 100%;
  }
}
