.timesUpContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1.5rem;
  width: 583px;
  height: 313px;
  border: none;
}

.timesUpText {
  margin-bottom: 1rem;
  border: none;
}

.timesUpButton {
  width: 278px;
  height: 52px;
  border-radius: 26px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  background-color: #fdb12a;
  border: none;
}

.timesUpButton:hover:enabled {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.timesUpButton:active:enabled {
  background-color: #f29b00;
  box-shadow: none;
}

.timesUpButton:disabled {
  background-color: #d8d8d8;
  color: #828282;
}
