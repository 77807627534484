.assessmentScoresContents {
  display: grid;
  grid-template-columns: 1fr 32.2%;
  grid-template-rows: 40% 1fr;
  column-gap: 25px;
  grid-template-areas:
    'assessmentLeft getCertificate'
    'dataTableRow dataTableRow';
}

.assessmentLeft {
  grid-area: assessmentLeft;
}

.GetCertificate {
  grid-area: getCertificate;
}
.dataTableRow {
  grid-area: dataTableRow;
}

@media screen and (max-width: 1272px) {
  .assessmentScoresContents {
    grid-template-columns: 1fr 32%;
    grid-template-rows: 38.5% 1fr;
  }
}

@media screen and (max-width: 1190px) {
  .assessmentScoresContents {
    display: flex;
    flex-direction: column;
  }
}
