.checkIcon {
  transform: scale(4);
  margin-bottom: 5rem;
}

.passwordResetContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.passwordResetContainer h1 {
  font-weight: 800;
  color: #000000;
  font-size: 32px;
}

.pageContainer h1 {
  margin: 0rem;
}

.subtitle {
  width: 100%;
  text-align: center;
}
