.forgotPasswordContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f7f8fc;
  height: 100vh;
}

.backSignInButton {
  font-size: 18px;
  margin-top: 1rem;
}

.backSignInButton a {
  font-weight: 700;
  color: #000000;
}

.backSignInButton a:hover {
  color: #000000;
}
