.footerLoginContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  z-index: 1;
}
.footerDashboardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  z-index: -1;
}
.noFooterDisplay {
  display: none;
}
.copyrightAndSocialsHome {
  display: flex;
  flex-direction: row;
  margin: 0 0 0 15rem;
}
.copyrightAndSocialsSignIn {
  display: flex;
  flex-direction: row;
  margin: 0 0 0 0;
}

.copyrightAndTitle {
  display: flex;
  flex-direction: row;
}

.developerInfo {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 1198px) {
  .footerLoginContainer,
  .footerDashboardContainer {
    flex-direction: column;
  }

  .footerDashboardContainer .developerInfo {
    margin-left: 15rem;
  }
}

@media screen and (max-width: 900px) {
  .copyrightAndSocialsHome,
  .footerDashboardContainer .developerInfo {
    margin-left: 0;
  }
}

@media screen and (max-width: 607px) {
  .copyrightAndTitle {
    margin-right: 0 !important;
    align-items: center;
    gap: 0.5rem;
  }
  .copyrightAndSocialsSignIn {
    flex-direction: column;
    margin-right: 0;
  }
  .companyTitle {
    margin-right: 0 !important;
  }
}
