.LoaderPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.LoaderPage h1 {
  color: #000000;
  font-size: 32px;
  font-weight: 800;
}

.loaderContainer h1 {
  color: #000000;
  font-size: 32px;
  font-weight: 800;
  padding: 2rem;
}

.LoaderPage p {
  flex: 1;
}

.loaderContainer {
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  align-self: center;
}

@media screen and (max-height: 655px) {
  .LoaderPage {
    padding-bottom: 4rem;
    height: 100vh;
  }

  .instructionsContainer {
    max-width: 900px !important;
    height: 80vh !important;
  }

  .instructionsContainer h1 {
    margin: 2rem 0 2rem 0 !important;
  }

  .instructionBtnContainer {
    margin-bottom: 1rem !important;
  }
}
