.signUpPage {
  display: flex;
  justify-content: center;
}
.componentHelperText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 95%;
}
.signUpBox {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 90%;
  justify-content: center;
}
.signUpForm {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: max-content;
}
.consentRow {
  display: flex;
  flex-direction: row;
  width: 80%;
  align-items: flex-start;
  font-family: 'Nunito', sans-serif;
}
input {
  margin: 5px 5px 5px 5px;
}
.userTypeBox {
  max-width: 90%;
  width: 100%;
  height: 100px;
  padding-left: 10px;
  border: solid;
  border-radius: 15px;
  display: flex;
  justify-content: flex-start;
  padding-top: 10px;
  border-color: #cfcfcf;
  font-family: 'Nunito', sans-serif;
}
.userTypeBoxText {
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
  padding-right: 10px;
  text-align: start;
  font-weight: bold;
  font-family: 'Nunito', sans-serif;
}
.userTypeBoxSubText {
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
  padding-right: 10px;
  text-align: start;
  padding-top: 10px;
  font-family: 'Nunito', sans-serif;
}
.formColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;
  width: 100%;
}
.formColumn-instructions {
  align-self: flex-start;
  margin-left: 20px;
  font-weight: 700;
  font-size: 18px;
}
.formColumn-instructions span,
.consentLabel span {
  font-weight: 400;
  color: #e20000;
}
.leftHalf fieldset,
.rightHalf fieldset {
  border-radius: 11px;
  width: 100%;
  font-family: 'Nunito', sans-serif;
}
.formRow {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.leftHalf {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 10px;
  flex-direction: column;
}
.rightHalf {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 10px;
  flex-direction: column;
}
.consentColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin-top: 0.5rem;
}
button.mediumButton {
  width: 409px;
  height: 52px;
  border-radius: 26px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  background-color: #fdb12a;
  border: none;
}
.createButtonRow {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.contactRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  padding-top: 20px;
  font-size: 18px;
}
.contactButton {
  border: none;
  cursor: pointer;
  background-color: transparent;
  text-decoration: underline;
  font-weight: 800;
  font-size: 18px;
}
.consentLabel {
  padding-top: 1px;
  padding-left: 10px;
  text-align: start;
}
.callToActionButtons {
  margin-top: 40px;
  margin-bottom: 40px;
}
.inputBox {
  max-width: 400px;
  width: 95%;
  height: 50px;
  padding-left: 10px;
  border: solid;
  border-width: 1px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
}
.inputInput {
  width: 100%;
  max-width: 90%;
  color: 'black';
}
.inputIconRow {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: none;
  background-color: transparent;
}
.signUpTitle {
  font-weight: bold;
  font-size: 30px;
}
input:focus {
  outline: none;
}
.errorMessage {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 5px;
  text-align: start;
  max-width: 400px;
  font-size: 14px;
}
.css-1wc848c-MuiFormHelperText-root {
  font-family: 'Nunito' sans-serif !important;
  font-size: 14px !important;
  color: #e20000 !important;
}
.passwordErrorMessage {
  display: flex;
  width: 90%;
  height: 60px;
  text-align: start;
  max-width: 400px;
}
.consentList {
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.activeLabel {
  border: solid #2b87f2;
}
.passwordInstruction {
  border: none;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 15px;
  margin-top: 6px;
  margin-left: 2.2rem;
  align-self: flex-start;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
}
@media (min-width: 900px) {
  .consentList {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .errorMessage {
    display: flex;
    justify-content: flex-start;
    width: 95%;
    max-width: 700px;
    height: 15px;
    padding-bottom: 5px;
    font-family: 'Nunito', sans-serif;
  }
  .formColumn {
    display: flex;
    flex-direction: column;
  }
  .signUpPage {
    display: flex;
    justify-content: center;
  }
  .signUpBox {
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 900px;
  }
  .signUpForm {
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
  }
  .consentRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    max-width: 700px;
    font-family: 'Nunito', sans-serif;
  }
  input {
    margin: 5px 5px 5px 5px;
  }
  .userTypeBox {
    max-width: 400px;
    width: 100%;
    height: 100px;
    padding-left: 10px;
    border: solid;
    border-radius: 15px;
    display: flex;
    justify-content: flex-start;
    padding-top: 10px;
    border-color: #cfcfcf;
    font-family: 'Nunito', sans-serif;
  }
  .userTypeBoxText {
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
    padding-right: 10px;
    text-align: start;
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
  }
  .userTypeBoxSubText {
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
    padding-right: 10px;
    text-align: start;
    padding-top: 10px;
    font-family: 'Nunito', sans-serif;
  }
  .formRow {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .leftHalf {
    width: 50%;
    display: flex;
    align-items: flex-end;
    padding-right: 10px;
    flex-direction: column;
  }
  .rightHalf {
    width: 50%;
    display: flex;
    align-items: flex-start;
    padding-left: 10px;
    flex-direction: row;
  }
  .consentColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: none;
  }
  .contactRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    padding-top: 20px;
  }
  .contactButton {
    border: none;
    background-color: transparent;
    text-decoration: underline;
    font-weight: bold;
  }
  .consentLabel {
    padding-left: 10px;
    padding-top: 1px;
    text-align: start;
  }
  .inputBox {
    max-width: 400px;
    width: 100%;
    height: 50px;
    padding-left: 10px;
    border: solid;
    border-width: 1px;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    margin-top: 0px;
  }
  .inputInput {
    border: none;
    width: 100%;
    max-width: 400px;
  }
  .inputIconRow {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border: none;
    background-color: transparent;
  }
  .signUpTitle {
    font-weight: bold;
    font-size: 30px;
  }
  input:focus {
    outline: none;
  }
  .passwordErrorMessage {
    display: flex;
    width: 90%;
    height: 60px;
    text-align: start;
    max-width: 400px;
    padding: 10px;
  }
  .activeLabel {
    border: solid #2b87f2;
  }
  .instructionColumn {
    display: flex;
    flex-direction: column;
  }
}
