.imagePreview > div > img {
  margin-top: 1rem;
  max-width: 100%;
}

.btnHandler {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.btn-linkedin {
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  font-weight: bolder;
  background-color: #fdb12a;
  color: #000;
  padding: 15px 50px;
  text-align: center;
  text-decoration: none;
  border-radius: 30px;
  border: #fdb12a;
  width: 278px;
  cursor: pointer;
  transition: all 0.3s 0s ease-in-out;
}
.btn-download {
  color: #000;
  font-size: 18px;
  margin-top: 3rem;
}
.certImg {
  height: 35rem;
}

.CertificateHandling,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}
.modal-Prev-content {
  margin-top: 5rem;
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #fff;
  padding: 14px 28px;
  border-radius: 30px;
  width: 50rem;
  height: 50rem;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
  font-weight: bolder;
}

.imagePreview > div > img {
  max-width: 100%;
}

.btn-download {
  color: #000;
  font-size: 1.5rem;
  margin-top: 3rem;
}
.certImg {
  height: 35rem;
}

.CertificateHandling,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
  font-weight: bolder;
}

.hidden-component {
  display: none;
}
.btnPng {
  background-color: transparent;
  border-style: none;
  text-decoration: underline;
  text-underline-offset: 3px;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  font-weight: bolder;
  margin-left: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

@media screen and (max-width: 900px) {
  .modal-Prev-content {
    height: 95vh;
    width: 100vw;
  }

  .purchasedCertificateTitle {
    padding-top: 1rem !important;
  }

  .imagePreview > div > img {
    margin-top: 0;
  }

  .btnHandler {
    margin-top: 1rem;
  }
}

@media screen and (max-height: 807px) {
  .modal-Prev-content {
    height: 45rem;
    width: 48rem;
    overflow-y: scroll;
  }

  .purchasedCertificateTitle {
    padding-top: 1rem !important;
  }

  .btnHandler {
    margin-top: 0.5rem;
  }
}
