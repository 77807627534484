.Home {
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr 355px;
  grid-template-rows: 500px 1fr;
  column-gap: 25px;
  padding-bottom: 6rem;
  grid-template-areas:
    'homeComponents rightSideBar'
    'homeComponents rightSideBar';
}

.rightSideBar {
  grid-area: rightSideBar;
}

.homeComponents {
  margin-top: 1.5rem;
  grid-area: homeComponents;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 981px) and (max-width: 1300px) {
  .Home {
    grid-template-columns: 1fr 32.8%;
  }
}

@media screen and (max-width: 1250px) {
  .Home {
    display: flex;
    flex-direction: column;
  }
}
