.AdminEditToggle button {
  display: flex;
  flex-direction: row;
  text-transform: none;
  color: #000;
  font-family: 'Nunito';
  font-weight: bold;
  font-size: 18px;
  border: 1px solid #87888a;
  border-radius: 32px;
  width: 231px;
  float: right;
}

.editArrow {
  position: absolute;
  right: 10%;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  width: 231px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  border-radius: 9px !important;
  margin-top: 0.2rem;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper li {
  font-size: 16px;
  font-family: 'Nunito';
  text-align: center;
}
