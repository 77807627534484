.QuestionTypes {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.GrammarSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 4rem 1rem 2rem;
  border-radius: 10px;
  margin-bottom: 2rem;
  width: 100%;
}

.ReadingSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 4rem 1rem 2rem;
  border-radius: 10px;
  margin-bottom: 2rem;
  width: 100%;
}

.ListeningSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 4rem 1rem 2rem;
  border-radius: 10px;
  width: 100%;
}

.PartDescription {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 2rem;
}

.sectionDescription {
  width: 100%;
  height: 100%;
  align-items: center;
}

@media only screen and (max-width: 724px) {
  .QuestionTypes {
    display: flex !important;
    flex-direction: column;
  }
}

@media only screen and (min-width: 725px) and (max-width: 900px) {
  .PartDescription {
    margin-left: 1rem;
  }
  .PartDescription span {
    font-size: 18px;
  }
  .ReadingSection,
  .ListeningSection,
  .GrammarSection {
    padding: 0.8rem 1rem 0.8rem 1rem;
    margin-bottom: 0;
  }
  .QuestionTypes {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

@media only screen and (min-width: 1400px) {
  .QuestionTypes {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .ReadingSection,
  .ListeningSection,
  .GrammarSection {
    margin-bottom: 0;
    height: 150px;
  }

  .PartDescription {
    flex-direction: column;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1400px) {
  .PartDescription {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .ReadingSection,
  .ListeningSection,
  .GrammarSection {
    width: 100%;
  }
}
