.myCertText {
  margin-left: 0;
  font-size: x-large;
  margin-bottom: 29px;
}

.rightSideBar {
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  font-family: 'Nunito', sans-serif;
  border-radius: 15px;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  margin-right: none;
}

.certCard {
  border-radius: 20px;
  width: 100%;
  height: 22rem;
  background: url('../../../src/assets/certificateBanner.png');
  background-position: center;
  background-size: cover;
  border-radius: 10;
  background-repeat: no-repeat;
  margin: 0;
}

.emptyCertCard {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f7f7f7 !important;
  width: 100%;
  height: 22rem;
}

.cardContent {
  margin-top: 12rem;
  text-align: center;
}
.textMid {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
}
.textEnd {
  margin-top: 2rem;
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
  text-align: left;
}

.myCertificateContainer {
  margin-top: 1.5rem;
}

.myCertificateContainer h2 {
  font-weight: 800;
  margin-top: 0;
  color: '#000000';
}

.btnGet {
  font-family: 'Nunito', sans-serif;
  width: 100%;
  height: 52px;
  border-radius: 26px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  background-color: #fdb12a;
  border: none;
}

.btnGet:hover:enabled {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.btnGet:active:enabled {
  background-color: #f29b00;
  box-shadow: none;
}
.btnGet:disabled {
  background-color: #d8d8d8;
  color: #828282;
}

@media screen and (min-width: 1251px) and (max-width: 1300px) {
  .rightSideBar {
    padding: 1.2rem;
  }

  .myCertText {
    margin-bottom: 29px;
  }

  .cardContent {
    margin-top: 11rem !important;
  }
}

@media screen and (min-width: 901px) and (max-width: 1250px) {
  .rightSideBar {
    padding: 2rem;
  }

  .myCertText {
    margin-top: 35px !important;
  }

  .cardBar {
    display: flex;
    flex-direction: row !important;
  }

  .certCard {
    width: 90%;
    height: 50%;
  }

  .textEnd {
    margin-top: 1rem;
    text-align: center;
  }

  .rightSideBarBottomContents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2rem;
    gap: 2rem;
  }

  .cardContent {
    margin-top: 10rem !important;
  }
}

@media screen and (max-width: 980px) {
  .certCard {
    width: 100%;
  }

  .rightSideBarBottomContents {
    margin: 1rem;
  }
}
