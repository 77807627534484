.smallButton {
  width: 231px;
  height: 52px;
  border-radius: 26px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  background-color: #fdb122;
  border: none;
}

.smallButton:hover:enabled {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.smallButton:active:enabled {
  background-color: #f29b00;
  box-shadow: none;
}

.smallButton:disabled {
  background-color: #d8d8d8;
  color: #828282;
}
