.cardContainerBelaPage {
  background-color: white;
  padding: 3rem;
  border-radius: 30px;
  box-shadow: rgba(149, 157, 165, 0.15) 0px 8px 24px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 30px;
  padding: 2.5rem;
}

.cardContainerBelaPage > p {
  color: #000;
}

.langToggle {
  align-self: flex-end;
  margin: 0 0 1.5rem 30rem;
}

.subtitle {
  /* width: 90%; */
  margin: 16px 0 16px 0;
  text-align: center;
}

.testRule {
  text-align: left;
  font-size: 18px;
}

.testRules {
  text-align: left;
}

.pageTitle {
  font-weight: 800;
  color: #000;
  font-size: 32px;
}
