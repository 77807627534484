.enterTextForm {
  background-color: white;
  padding: 3rem;
  border-radius: 30px;
  box-shadow: rgba(149, 157, 165, 0.15) 0px 8px 24px;
  margin: 0 auto;
  display: flex;
  width: 513px;
  height: 471px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: #ffffff;
  border-radius: 30px;
}

.enterTextField {
  width: 400px;
  align-self: center;
}

.enterTextField fieldset {
  border-radius: 11px;
}

.submitPasswordButton {
  margin-top: 3rem !important;
}

.subtitle {
  width: 80%;
  margin-bottom: 2rem;
  text-align: center;
}

.passwordErrorContainer {
  height: 2rem;
  align-self: flex-start;
}

.passwordError {
  margin: 0 0 0 3rem;
  color: #e20000;
}

.enterTextForm > h1 {
  width: 100%;
  font-weight: 800;
  font-size: 32px;
  color: #000000;
}

.submitButton {
  margin-top: 3rem;
}
