.purchasedCertBtns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.purchasedCert {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.viewCertBtn {
  margin-top: 2rem;
  background-color: transparent;
  border: none;
  font-size: 18px;
  font-weight: 700;
  text-decoration: underline;
  padding: 0;
  cursor: pointer;
}

.purchasedCertPrev {
  width: 100%;
}

@media screen and (max-width: 1187px) {
  .purchasedCardContentsContainer {
    margin: 2rem;
  }

  .purchasedCardContentsContainer:nth-child(1) {
    margin-top: 0;
  }
}
