*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  line-height: 1.5; /* GLOBAL */
  background-color: #104d64;
}

.signInSlogan {
  z-index: 0;
  width: 30%;
  position: absolute;
  bottom: 0;
  left: 1.5em;
}

.signInDecor {
  width: 55%;
  z-index: 0;
  position: absolute;
  top: -5rem;
  right: 5%;
}

.signInPage {
  display: grid;
  grid-template-columns: minmax(0px, 60%) 1fr;
  gap: 1em;
  min-height: 85vh;
  overflow: hidden;
}

.signInLeftHalf {
  display: flex;
  background-image: url(../../assets/signIn-without.png);
  grid-column: 1;
  flex-grow: 1;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
  margin-right: 2.3em;
  align-items: center;
  justify-content: center;
}

.signInRightHalf {
  z-index: 1;
  display: flex;
  grid-column: 2;
  align-items: center;
  justify-content: center;
}
.signInBox {
  display: flex;
  height: 82vh;
  flex-direction: column;
  justify-content: center;
  margin-left: 0;
  margin-top: 0;
  margin-right: 1.5em;
  margin-bottom: 1.5em;
  background-color: white;
  border-radius: 30px;
  padding: 4em;
  gap: 0.5em;
}

.signInTitle {
  font-size: 32px;
  font-weight: 800;
}

.signInSubTitle {
  margin-bottom: 2em;
}

.textFieldContainer {
  width: 100%;
}

.signUpButton {
  font-size: 18px;
  font-weight: 700;
  background: transparent;
  border: none;
}

.forgotButton {
  display: flex;
  width: max-content;
  align-self: flex-end;
  background: transparent;
  border: none;
  font-size: 14px;
  font-weight: 700;
}

@media only screen and (min-width: 1920px) {
  .signInSlogan {
    width: 26%;
  }
}

@media only screen and (min-width: 1101px) and (max-height: 641px) {
  .signInSlogan {
    bottom: -20px;
  }
}

@media only screen and (max-width: 1100px) {
  .signInSlogan {
    width: 35%;
  }
}

@media only screen and (max-width: 968px) {
  .signInPage {
    display: flex;
    gap: 0;
  }
  .signInLeftHalf {
    display: none;
    width: 0;
  }
  .signInBox {
    width: 100%;
    margin-right: 0;
  }
  .signInRightHalf {
    width: 65%;
    margin: 0 auto;
  }

  .signInSlogan {
    display: none;
  }

  .signInDecor {
    display: none;
  }
}

@media only screen and (max-width: 895px) {
  .signInRightHalf {
    width: 80%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 667px) {
  .signInRightHalf {
    width: 95%;
    margin: 0 auto;
  }
  .signInBox {
    padding: 1.5rem;
  }
}

@media only screen and (max-height: 522px) {
  .signInSlogan {
    bottom: -120px;
  }

  .signInBox {
    height: 100vh;
    align-items: center;
    width: 40vw;
    padding: 3em;
  }
}

@media only screen and (max-width: 1280px) and (min-height: 523px) and (max-height: 600px) {
  .signInSlogan {
    bottom: -115px;
  }

  .signInBox {
    height: 96vh;
    align-items: center;
    padding: 3em;
  }
}

@media only screen and (width: 1280px) and (min-height: 601px) and (max-height: 680px) {
  .signInSlogan {
    bottom: -52px;
  }

  .signInBox {
    height: 88vh;
    align-items: center;
    width: 40vw;
  }
}

@media only screen and (min-height: 782px) {
  .signInSlogan {
    bottom: 25px;
  }
}

@media only screen and (min-height: 917px) {
  .signInSlogan {
    bottom: 50px;
  }
}
