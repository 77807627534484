.OverviewUsers {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.overviewTitle {
  font-size: 24px;
  font-weight: 800;
  margin-top: 0;
  margin-bottom: 20px;
}

.overviewContainer {
  display: flex;
  align-items: center;
  background-color: #dbdefe;
  border-radius: 15px;
  padding: 1rem;
  height: 100%;
}

.overviewIconContainer {
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  height: 95%;
  align-items: center;
  justify-content: center;
  width: 40%;
}

.overviewDescription {
  text-align: center;
  margin: 0 auto;
  width: 60%;
  padding-left: 1rem;
}
