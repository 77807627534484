.App-header {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.individualDashboardMainContainer {
  display: grid !important;
  grid-template-columns: 248px 1fr;
  grid-template-rows: 40px 1fr 40px;
  grid-template-areas:
    'Navbar header'
    'Navbar routeDiv'
    'Navbar footer';
  background-color: #f7f8fc;
  width: 100%;
}

.Navbar {
  grid-area: sidenav;
  text-align: left;
}

.routeDiv {
  grid-area: routeDiv;
  padding: 0 35px 0 35px;
  text-align: left;
  height: fit-content;
  background-color: rgb(247, 248, 252);
  margin: 0 auto;
  width: 100%;
}

.dashboardFooter {
  grid-area: footer;
}

@media only screen and (min-width: 1500px) {
  .routeDiv {
    width: 80%;
  }
}

@media screen and (max-width: 900px) {
  .individualDashboardMainContainer {
    grid-template-columns: 1fr;
  }

  .routeDiv {
    padding: 0 35px 0 0;
  }
}
