.AdminHome {
  background-color: #f7f8fc;
  height: 100vh;
  width: 100vw;
  padding: 0;
  display: grid;
  grid-template-columns: 248px 1fr;
  grid-template-rows: 40px 1fr 40px;
  grid-template-areas:
    'sidenav header'
    'sidenav adminAssessmentComponentsContainer'
    'sidenav footer';
}

.adminSidebar {
  grid-area: sidenav;
  text-align: left;
}

.adminAssessmentComponentsContainer {
  grid-area: adminAssessmentComponentsContainer;
  text-align: left;
  height: fit-content;
  padding: 1.5rem 35px 0 35px;
  margin: 0 auto;
  width: 100%;
  overflow: scroll;
}

.adminComponents {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  justify-content: space-between;
}

.adminComponents h2 {
  margin: 0;
  font-weight: 800;
  font-size: 24px;
}
.adminComponents button {
  float: right;
}

@media screen and (min-width: 725px) and (max-width: 899px) {
  .AdminHome {
    height: 100vh;
  }
}

@media screen and (max-width: 900px) {
  .AdminHome {
    grid-template-columns: 0fr;
  }

  .adminAssessmentComponentsContainer {
    padding-left: 0;
  }
}

@media screen and (min-width: 901px) and (max-width: 999px) {
  .AdminHome {
    height: auto;
  }
}

@media only screen and (min-width: 1500px) {
  .adminAssessmentComponentsContainer {
    width: 80%;
  }
}
