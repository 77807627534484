/* body.active-modal {
  overflow-y: hidden;
} */

.popUp-modal-content h2 {
  font-size: 32px;
  margin-top: 1rem;
  align-self: flex-start;
}

.certificatePreviewContainer {
  display: grid;
  grid-template-columns: 55% 1fr;
  width: 100%;
  gap: 1rem;
}

.certificateInfo {
  width: 100%;
}

.certificateSample {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}

.btnExit {
  margin-top: 2rem !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 25px;
  align-self: flex-end;
}

.btnExit button {
  font-family: 'Nunito';
}

.btn-cancel {
  color: #000;
  font-size: 18px;
  font-weight: 700;
}

.btn-cancel:hover {
  color: #000;
}

.imgPrev {
  width: 100%;
}

.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.modal-pop {
  /* width: 100vw; */
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}

.popUp-modal-content {
  min-height: 70%;
  /* min-width: 65%; */
  width: 75%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  line-height: 1.4;
  background: #fff;
  padding: 2rem;
  border-radius: 30px;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
  font-weight: bolder;
  font-size: 45px !important;
  cursor: pointer;
}

.listOptions {
  border-radius: 1.5rem;
  background-color: #f7f8fc;
}

.listOptions ul {
  padding: 1.5rem;
  margin-bottom: 0;
}

.listOptions ul li {
  font-size: 16px;
  list-style-type: none;
  text-align: justify;
  font-size: 1.2rem;
  line-height: 3;
}

.priceTag {
  text-align: center;
}

.priceTag span {
  font-size: 24px;
  font-weight: 700;
}

.listOptions ul li:before {
  content: '✓';
  color: grey;
  font-weight: bolder;
  font-size: medium;
  margin-left: 10px;
  margin-right: 10px;
}

.proceedCheckoutButton {
  width: 278px;
  height: 52px;
  border-radius: 26px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  background-color: #fdb12a;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
}
.proceedCheckoutButton:hover:enabled {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.proceedCheckoutButton:active:enabled {
  background-color: #f29b00;
  box-shadow: none;
}

@media only screen and (max-width: 1135px) {
  .popUp-modal-content {
    min-width: 90%;
    min-height: 90%;
  }
}

@media only screen and (max-width: 850px) {
  .modal,
  .overlay {
    width: auto;
    height: auto;
  }
}

@media only screen and (max-width: 834px) {
  .modal,
  .overlay,
  .model-content {
    overflow: scroll;
  }

  .overlay {
    background-color: #ffffff;
  }

  .popUp-modal-content {
    width: 100%;
    height: 100%;
    overflow: scroll;
  }

  .certificatePreviewContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 55% 1fr;
    grid-template-areas:
      'certificateSample'
      'certificateInfo';
    width: 100%;
    gap: 1rem;
  }

  .popUp-modal-content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
      'header'
      'certificateSample'
      'certificateInfo';
    width: 100%;
  }

  .model-content h1 {
    grid-area: header;
    margin-bottom: 2rem;
  }

  .certificateSample {
    grid-area: certificateSample;
    margin-top: 2rem;
  }

  .certificateInfo {
    grid-area: certificateInfo;
    margin-top: 2rem;
  }

  .btnExit {
    width: 100%;
    margin-bottom: 2rem;
  }

  .btnExit button {
    width: 278px;
    height: 52px;
    border-radius: 26px;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    background-color: #fdb12a;
    border: none;
    cursor: pointer;
  }
}
